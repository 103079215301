import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { GeofencingGuard } from "../../utilities/geofencing-guard/geofencing-guard";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";


const logoImage = require('./assets/Logo color White.png')
const eeepEnglishBig = require('./assets/eeepEnglisgBig.webp')
const eeepGreekBig = require('./assets/eeepGreekBig.webp')
const eeepSmall = require('./assets/eeepSmall.webp')

const css = `
    <style>
  footer-container .text-eeep{
   font-size: 1.3rem;
   color: white;
   font-family: Verdana;
   }
         footer-container .container {
             max-width: 100%;
             margin:0;
             background-color: #1c232c;
             border-top: #B22A23 solid;
        }
         footer-container a {
             text-decoration: none;
             color: #adb5bd;
        }
         footer-container a:hover {
             color: #B22A23;
        }
         footer-container .somedesign {
             color: #ffffff;
             margin-right: 10px;
        }
         footer-container .somedesign:hover{
             color: #B22A23;
             cursor: pointer;
        }
         .footer-copyright {
             border-top: #adb5bd solid 1px;
        }
         .footer-title {
             color:#ffffff;
        }

        @media screen and (orientation:portrait) {
            
        }
        @media screen and (orientation:landscape) {
           
        }
        
        
    /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
footer-container .text-eeep{
   font-size: 0.7rem;
   color: white;
   font-family: Verdana;
   }
   
footer-container   .footer-title {
             font-size: 0.8rem;
             font-weight: 500;
        }
footer-container .text-resize-footer{
font-size: 0.5rem;
font-weight: 300;
}

 footer-container .text-eeep-big{
   font-size: 0.6rem;
   color: white;
   font-family: Verdana;
   }
   
     footer-container .text-eeep-small{
     
      font-size: 0.6rem;
   color: white;
   font-weight: 500;
   font-family: Verdana;
     }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
footer-container .text-eeep{
   font-size: 0.8rem;
   color: white;
   font-family: Verdana;
   }
   
footer-container   .footer-title {
             font-size: 0.9rem;
             font-weight: 500;
        }
footer-container .text-resize-footer{
font-size: 0.6rem;
font-weight: 300;
}

 footer-container .text-eeep-big{
   font-size: 0.7rem;
   color: white;
   font-family: Verdana;
   }
   
     footer-container .text-eeep-small{
     
      font-size: 0.7rem;
   color: white;
   font-weight: 500;
   font-family: Verdana;
     }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
footer-container .text-eeep{
   font-size: 0.9rem;
   color: white;
   font-family: Verdana;
   }
   
footer-container   .footer-title {
             font-size: 1rem;
             font-weight: 500;
        }
footer-container .text-resize-footer{
font-size: 0.7rem;
font-weight: 300;
}

 footer-container .text-eeep-big{
   font-size: 0.8rem;
   color: white;
   font-family: Verdana;
   }
   
     footer-container .text-eeep-small{
     
      font-size: 0.8rem;
   color: white;
   font-weight: 500;
   font-family: Verdana;
     }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
footer-container .text-eeep{
   font-size: 0.9rem;
   color: white;
   font-family: Verdana;
   }
   
footer-container   .footer-title {
             font-size: 1rem;
             font-weight: 500;
        }
footer-container .text-resize-footer{
font-size: 0.7rem;
font-weight: 300;
}

 footer-container .text-eeep-big{
   font-size: 0.8rem;
   color: white;
   font-family: Verdana;
   }
   
     footer-container .text-eeep-small{
     
      font-size: 0.8rem;
   color: white;
   font-weight: 500;
   font-family: Verdana;
     }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
footer-container .text-eeep{
   font-size: 1rem;
   color: white;
   font-family: Verdana;
   }
   
footer-container   .footer-title {
             font-size: 1.2rem;
             font-weight: 500;
        }
footer-container .text-resize-footer{
font-size: 0.8rem;
font-weight: 300;
}

 footer-container .text-eeep-big{
   font-size: 0.9rem;
   color: white;
   font-family: Verdana;
   }
   
     footer-container .text-eeep-small{
     
      font-size: 0.9rem;
   color: white;
   font-weight: 500;
   font-family: Verdana;
     }
}    
        
        
    </style>
`

export class FooterContainer extends BaseComponent {

  initCallback() {
    const dynamicHtml = /*html*/ `
    <a href="${window.globalSocials.discord}" target="_blank"><i class="somedesign bi bi-discord h3"></i></a>
          <a href="${window.globalSocials.instagram}" target="_blank"> <i class="somedesign bi bi-instagram h3"></i></a>
         <a href="${window.globalSocials.facebook}" target="_blank">  <i class="somedesign bi bi-facebook h3"></i></a>
         <a href="${window.globalSocials.twitter}" target="_blank">  <i class="somedesign bi bi-twitter-x h3"></i></a>
          <!--        <a  target="_blank">   <i class="somedesign bi bi-tiktok h3"></i></a> -->
        <a href="${window.globalSocials.twitch}" target="_blank">   <i class="somedesign bi bi-twitch h3"></i></a>
    `;
    document.querySelector('.footer-some-icons').innerHTML = dynamicHtml;
    document.head.insertAdjacentHTML("beforeend", css)
    GeofencingGuard.getUserCountry(function (userCurrentCountry) {
      if (userCurrentCountry === 'GR') {
        document.getElementById('eeepRow').style.display = ''
        if (I18n.getUsersPreferredLanguage() === 'el')
          document.getElementById('eeepGreekPart').style.display = ''
        else
          document.getElementById('eeepEnglishPart').style.display = ''
      }
      document.getElementById('footerFollowUs').innerText = I18n.translateString('footer.followUs')
      document.getElementById('footerUsefulLinks').innerText = I18n.translateString('footer.usefulLinks')
      document.getElementById('footerContactUs').innerText = I18n.translateString('footer.contactUs')
      document.getElementById('footerEULA').innerText = I18n.translateString('footer.eula')
      document.getElementById('footerPrivacy').innerText = I18n.translateString('footer.privacy')
      document.getElementById('footerFaq').innerText = I18n.translateString('footer.faq')
      document.getElementById('footerAboutUs').innerText = I18n.translateString('footer.aboutUs')
      document.getElementById('footerAboutContent').innerText = I18n.translateString('footer.about')
      document.getElementById('footerAuthors').innerText = I18n.translateString("footer.Authors")
    })
    document.getElementById('footerFaq').addEventListener('click', () => {
      RouterAssistant.redirect('s_news', {
        article: "sixnes-erwtiseis"
      })
    })
    document.getElementById('footerPrivacy').addEventListener('click', () => {
      RouterAssistant.redirect('s_news', {
        article: "politiki-aporritou"
      })
    })
    document.getElementById('footerAuthors').addEventListener('click', () => {
      RouterAssistant.redirect('authors', {

      })
    })
    document.getElementById('footerEULA').addEventListener('click', () => {
      RouterAssistant.redirect('s_news', {
        article: "oroi-xrhseis"
      })
    })
  }
}

FooterContainer.template = /*html*/ `
<div class="container text-center ">
  <div class="footer-top-content row ">
    <div class="footer-social-media col-lg-6 col-md-6 col-xl-4 col-sm-12 mt-5 ">
      <p id="footerFollowUs" class="footer-title">Ακολουθήστε μας</p>
      <img src="${logoImage}" class="img-fluid m-2" alt="logo" style="width:4rem">
      <div class="footer-some-icons col mt-3">

      </div>
    </div>
    <div class="footer-usefull-links col-lg-6 col-md-6 col-xl-4 col-sm-12 mt-5 ">
      <p id="footerUsefulLinks" class="footer-title ">Χρήσιμα Links</p>
      <div class=" col mt-3">
        <a href="mailto: contact@myesports.gg">
          <p id="footerContactUs" class="text-resize-footer">Επικοινωνία</p>
        </a> 
        <a >
          <p id="footerEULA" class="text-resize-footer">Όροι Χρήσης</p>
        </a>
         <a >
        <p id="footerPrivacy" class="text-resize-footer">Πολιτική Απορρήτου</p>
       </a>
        <a >
        <p id="footerFaq" class="text-resize-footer">Συχνές Ερωτήσεις</p>
       </a>
       <a >
        <p id="footerAuthors" class="text-resize-footer">Αρθρογράφοι</p>
       </a>
      </div>
    </div>
    <div class="footer-aboutus col-lg-12 col-md-12 col-xl-4 col-sm-12 mt-5 ">
      <p id="footerAboutUs" class="footer-title ">About Us</p>
      <div class="col-8 offset-2 mt-3">
        <p id="footerAboutContent" class="text-resize-footer" style="text-align: justify">Καλώς ήρθατε στο MyEsports, τον ολοκληρωμένο προορισμό για κάθε φίλο των esports. Με μια βαθιά αγάπη και πάθος για τον κόσμο του gaming, δημιουργήσαμε έναν χώρο όπου η κοινότητα των esports θα μπορεί να βρει τα πάντα: από ζωντανές μεταδόσεις αγώνων και εκδηλώσεων μέχρι προγνωστικά, αναλύσεις και άρθρα για όλα τα είδη παιχνιδιών. Στοχεύουμε να καταστήσουμε το MyEsports την πιο μεγάλη και πλήρη κοινότητα gaming, παρέχοντας έναν χώρο όπου οι gamers μπορούν να συνδέονται, να μαθαίνουν και να ανταγωνίζονται. Με ένα ευρύ φάσμα περιεχομένου και διαδραστικών εμπειριών, η αποστολή μας είναι να εμπλουτίσουμε την εμπειρία κάθε gamer και να προωθήσουμε την ανάπτυξη των esports σε όλα τα επίπεδα. Έλα να γίνεις μέρος της επανάστασης των esports μαζί μας στο MyEsports - όπου τα πάντα για τα esports είναι εδώ, εσύ είσαι έτοιμος;</p>
      </div>
    </div>
  </div>
  <div id="eeepRow" class="footer-eeep row d-flex justify-content-center" style="display: none">
    <div id="eeepEnglishPart" class="col-12 d-flex align-items-center justify-content-center m-5 " style="display: none!important">
      <img src="${eeepEnglishBig}" class="img-fluid d-none d-lg-flex" alt="eeep" style="width: 10rem;height: auto">
      <img src="${eeepSmall}" class="img-fluid d-flex d-lg-none" alt="eeep" style="width: 5rem;height: auto">
      <span class="ms-2 text-eeep-big d-none d-lg-flex">21+ | RISK OF ADDICTION & LOSS OF PROPERTY | PLAY RESPONSIBLY | <br> KETHEA: 2109237777 | PLAY RESPONSIBLY </span>
      <span class="ms-2 text-eeep-small d-flex d-lg-none">21+ | RISK OF ADDICTION & LOSS OF PROPERTY | PLAY RESPONSIBLY</span>
         </div>
     <div  id="eeepGreekPart" class="col-12 d-flex align-items-center justify-content-center m-5 " style="display: none!important">
      <img src="${eeepGreekBig}" class="img-fluid d-none d-lg-flex" alt="eeep" style="width: 10rem;height: auto">
      <img  src="${eeepSmall}" class="img-fluid d-flex d-lg-none" alt="eeep" style="width: 5rem;height: auto">
      <span class="ms-2 text-eeep-big d-none d-lg-flex"> 21+ | ΑΡΜΟΔΙΟΣ ΡΥΘΜΙΣΤΗΣ ΕΕΕΠ | ΚΙΝΔΥΝΟΣ ΕΘΙΣΜΟΥ & ΑΠΩΛΕΙΑΣ ΠΕΡΙΟΥΣΙΑΣ | <br> ΓΡΑΜΜΗ ΒΟΗΘΕΙΑΣ ΚΕΘΕΑ: 2109237777 | ΠΑΙΞΕ ΥΠΕΥΘΥΝΑ</span>
      <span class="ms-2 text-eeep-small d-flex d-lg-none" > 21+ | ΑΡΜΟΔΙΟΣ ΡΥΘΜΙΣΤΗΣ ΕΕΕΠ | ΚΙΝΔΥΝΟΣ ΕΘΙΣΜΟΥ & ΑΠΩΛΕΙΑΣ ΠΕΡΙΟΥΣΙΑΣ </span>
    </div>
  </div>
  <div class="footer-copyright row">
    <div class="col mt-2">
      <p> &copy; MyEsports 2024</p>
    </div>
  </div>
</div>
    
`;

FooterContainer.reg('footer-container'); // components should always be 2 words seperated with a dash
