/*
* "kick-starter" as the name implies initiates all the required "stuff" of our application.
*/
import * as globalVar from "../global-var/global-var.js";
import { I18n } from "../i18n/i18n.js";
import { FirebaseAssistant } from "../firebase-assistant/firebase-assistant.js";
import { BootstrapAssistant } from "../bootstrap-assistant/bootstrap-assistant.js";
import { RouterAssistant } from "../router-assistant/router-assistant.js";
import { AnnouncementAssistant } from "../../components/announcement-assistant/announcement-assistant.js";
import { NetworkGuard } from "../../components/network-guard/network-guard.js";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { GeofencingGuard } from "../geofencing-guard/geofencing-guard";
import { SecurityAssistant } from "../security-assistant/security-assistant";


class KickStarter {
    static initiate() {
        GeofencingGuard.initiate()
        I18n.initiate();
        BootstrapAssistant.initiate();
        FirebaseAssistant.initiate();
        GridOrchestrator.initiate();
        RouterAssistant.initiate();
        // AnnouncementAssistant.initiate();
        // NetworkGuard.initiate();
        // SecurityAssistant.initiate();
    }
}

KickStarter.initiate();
