import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GamerShowcase } from "../gamer-showcase/gamer-showcase";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";


const css = `
    <style>
         @media screen and (orientation:portrait) {
             #PostContainerMessage{
                 color: red;
            }
        }
         @media screen and (orientation:landscape) {
             #PostContainerMessage{
                 text-align: center;
                 color: green;
            }
        }
    </style>
`

export class PostContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        window.addEventListener('resize', PostContainer.performOrientationChangeActions);
        PostContainer.performOrientationChangeActions();
        PostContainer.enableHomePageRedirect();
        GridOrchestrator.deactivateGameFilters()
    }

    static translations(isMobile = false) {
        // const variableText = isMobile ? I18n.translateString('postPage.mobileMessage') : I18n.translateString('postPage.desktopMessage')
        // document.getElementById('PostContainerMessage').innerText = `${I18n.translateString('postPage.generalMessage')} ${variableText}`;
        // document.getElementById('theButtonOfYourSister').innerText = I18n.translateString('postPage.redirectButton')
        // document.getElementById('theParagraphOfYourSister').innerText = `${I18n.translateString('postPage.variablesAre')}: ${JSON.stringify(window.currentPageVariables)}`
    }

    static performOrientationChangeActions() {
        if (window.location.href.includes('s_post')) {
            if (window.innerHeight > window.innerWidth) {
                PostContainer.translations(true)
            } else {
                PostContainer.translations()
            }
        }
    }

    static enableHomePageRedirect() {
        document.getElementById('theButtonOfYourSister').addEventListener('click', function (evt) {
            RouterAssistant.redirect('home', {
                "molisGirisa": 'ApoHome'
            })
        })
    }
}

PostContainer.template = /*html*/ `
    <div class="col text-center">

  <h1 id="PostContainerMessage" class="mt-5"></h1>
  <p id="theParagraphOfYourSister"></p>
  <button id="theButtonOfYourSister" class="btn btn-primary"></button>
  <gamer-showcase></gamer-showcase>
  
   <div class="mt-5">
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
            <p>A hypothetical component lives here with his family :)</p>
        </div>
    </div>
`;

PostContainer.reg('post-container');
