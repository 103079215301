const globalSocials = {
    discord: "https://discord.gg/3sU4u59qze",
    twitter: "https://x.com/myesportsgr",
    facebook: "https://www.facebook.com/myesportsgr",
    twitch: "https://www.twitch.tv/my_esports",
    instagram: "https://www.instagram.com/myesportsgr/",
    youtube: "https://www.youtube.com/@myesportsgr",
    tiktok: "https://www.tiktok.com/@myesports.gr",
};

// Attach the objects to the global window objects
window.globalSocials = globalSocials;
