import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { MatchesComponent } from "../matches-component/matches-component";


const css = `

`

export class MatchesContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GridOrchestrator.activateGameFilters()
        document.getElementById('matchesContainerHeader').innerText = I18n.translateString('navMainMenu.Matches')
    }
}

MatchesContainer.template = /*html*/ `
    <h1 id="matchesContainerHeader" style="font-size: 1px;color: rgb(15, 18, 23)"></h1>

         
      <div class="main-content col-lg-8 col-md-8 col-sm-8 col-xs-12 g-0 mt-2 ">
      
         <div class="home-matches home-components row m-2 p-2">
      <!-- placeholder starts -->
      <div id="placeholder-matches-big">
      <div class="col-12 w-100 mt-2 placeholder-glow" style="height: 50px">
      
      <span class=" placeholder rounded " style="width:500px;height: 40px"></span>
      
</div>
   <div class="row  mt-3 rounded text-white color-dark placeholder-glow" style="height:168px;">
  
    <div class="col-12 p-0 d-flex justify-content-between rounded ">
      <div class="text-start d-flex p-1 rounded-start placeholder w-25"></div>
      <div class="text-end  placeholder rounded me-2 mt-1 " style="width: 7rem; height: 20px"></div>
    </div>
    <!-- team 1 -->
    <div class="col-5 mt-2 d-flex justify-content-end align-items-center ">
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
    </div>
    <!-- score -->
    <div class="col-2  d-flex flex-column align-items-center justify-content-around">
      <p class=" m-0 placeholder rounded" style="width:2rem;"></p>
    </div>
    <!-- team 2 -->
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <div class=" img-circle-container d-flex justify-content-center  flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
    </div>
    <!-- botom bar -->
    <div class=" row p-0 g-0 my-2">
      <div class="col-4 p-0 m-0 mt-2" style="width: 33.3%;"></div>
      <div class=" col-4 text-center  p-0 m-0 mt-2   " style="width: 33.3%;">
        <span class="placeholder w-25 rounded" style="height: 20px"></span>
      </div>
      <div class=" col-4  text-end  p-0 m-0 pe-2 mt-2   " style="width: 33.3%; ">
        <span class="placeholder bg-danger w-25 rounded" style="height: 20px"></span>
      </div>
    </div>
  </div>
   <div class="row  mt-3 rounded text-white color-dark  placeholder-glow" style="height:168px;">
    <div class="col-12 p-0 d-flex justify-content-between rounded ">
      <div class="text-start d-flex p-1 rounded-start placeholder w-25"></div>
      <div class="text-end  placeholder rounded me-2 mt-1 " style="width: 7rem; height: 20px"></div>
    </div>
    <!-- team 1 -->
    <div class="col-5 mt-2 d-flex justify-content-end align-items-center ">
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
    </div>
    <!-- score -->
    <div class="col-2  d-flex flex-column align-items-center justify-content-around">
      <p class=" m-0 placeholder rounded" style="width:2rem;"></p>
    </div>
    <!-- team 2 -->
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <div class=" img-circle-container d-flex justify-content-center  flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
    </div>
    <!-- botom bar -->
    <div class=" row p-0 g-0 my-2">
      <div class="col-4 p-0 m-0 mt-2" style="width: 33.3%;"></div>
      <div class=" col-4 text-center  p-0 m-0 mt-2   " style="width: 33.3%;">
        <span class="placeholder w-25 rounded" style="height: 20px"></span>
      </div>
      <div class=" col-4  text-end  p-0 m-0 pe-2 mt-2   " style="width: 33.3%; ">
        <span class="placeholder bg-danger w-25 rounded" style="height: 20px"></span>
      </div>
    </div>
  </div>
   <div class="row  mt-3 rounded text-white color-dark  placeholder-glow" style="height:168px;">
    <div class="col-12 p-0 d-flex justify-content-between rounded ">
      <div class="text-start d-flex p-1 rounded-start placeholder w-25"></div>
      <div class="text-end  placeholder rounded me-2 mt-1 " style="width: 7rem; height: 20px"></div>
    </div>
    <!-- team 1 -->
    <div class="col-5 mt-2 d-flex justify-content-end align-items-center ">
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
    </div>
    <!-- score -->
    <div class="col-2  d-flex flex-column align-items-center justify-content-around">
      <p class=" m-0 placeholder rounded" style="width:2rem;"></p>
    </div>
    <!-- team 2 -->
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <div class=" img-circle-container d-flex justify-content-center  flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
    </div>
    <!-- botom bar -->
    <div class=" row p-0 g-0 my-2">
      <div class="col-4 p-0 m-0 mt-2" style="width: 33.3%;"></div>
      <div class=" col-4 text-center  p-0 m-0 mt-2   " style="width: 33.3%;">
        <span class="placeholder w-25 rounded" style="height: 20px"></span>
      </div>
      <div class=" col-4  text-end  p-0 m-0 pe-2 mt-2   " style="width: 33.3%; ">
        <span class="placeholder bg-danger w-25 rounded" style="height: 20px"></span>
      </div>
    </div>
  </div>
   <div class="row  mt-3 rounded text-white color-dark  placeholder-glow" style="height:168px;">
    <div class="col-12 p-0 d-flex justify-content-between rounded ">
      <div class="text-start d-flex p-1 rounded-start placeholder w-25"></div>
      <div class="text-end  placeholder rounded me-2 mt-1 " style="width: 7rem; height: 20px"></div>
    </div>
    <!-- team 1 -->
    <div class="col-5 mt-2 d-flex justify-content-end align-items-center ">
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
    </div>
    <!-- score -->
    <div class="col-2  d-flex flex-column align-items-center justify-content-around">
      <p class=" m-0 placeholder rounded" style="width:2rem;"></p>
    </div>
    <!-- team 2 -->
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <div class=" img-circle-container d-flex justify-content-center  flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
    </div>
    <!-- botom bar -->
    <div class=" row p-0 g-0 my-2">
      <div class="col-4 p-0 m-0 mt-2" style="width: 33.3%;"></div>
      <div class=" col-4 text-center  p-0 m-0 mt-2   " style="width: 33.3%;">
        <span class="placeholder w-25 rounded" style="height: 20px"></span>
      </div>
      <div class=" col-4  text-end  p-0 m-0 pe-2 mt-2   " style="width: 33.3%; ">
        <span class="placeholder bg-danger w-25 rounded" style="height: 20px"></span>
      </div>
    </div>
  </div>
   <div class="row  mt-3 rounded text-white color-dark  placeholder-glow" style="height:168px;">
    <div class="col-12 p-0 d-flex justify-content-between rounded ">
      <div class="text-start d-flex p-1 rounded-start placeholder w-25"></div>
      <div class="text-end  placeholder rounded me-2 mt-1 " style="width: 7rem; height: 20px"></div>
    </div>
    <!-- team 1 -->
    <div class="col-5 mt-2 d-flex justify-content-end align-items-center ">
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
    </div>
    <!-- score -->
    <div class="col-2  d-flex flex-column align-items-center justify-content-around">
      <p class=" m-0 placeholder rounded" style="width:2rem;"></p>
    </div>
    <!-- team 2 -->
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <div class=" img-circle-container d-flex justify-content-center  flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
    </div>
    <!-- botom bar -->
    <div class=" row p-0 g-0 my-2">
      <div class="col-4 p-0 m-0 mt-2" style="width: 33.3%;"></div>
      <div class=" col-4 text-center  p-0 m-0 mt-2   " style="width: 33.3%;">
        <span class="placeholder w-25 rounded" style="height: 20px"></span>
      </div>
      <div class=" col-4  text-end  p-0 m-0 pe-2 mt-2   " style="width: 33.3%; ">
        <span class="placeholder bg-danger w-25 rounded" style="height: 20px"></span>
      </div>
    </div>
  </div>
   <div class="row  mt-3 rounded text-white color-dark  placeholder-glow" style="height:168px;">
    <div class="col-12 p-0 d-flex justify-content-between rounded ">
      <div class="text-start d-flex p-1 rounded-start placeholder w-25"></div>
      <div class="text-end  placeholder rounded me-2 mt-1 " style="width: 7rem; height: 20px"></div>
    </div>
    <!-- team 1 -->
    <div class="col-5 mt-2 d-flex justify-content-end align-items-center ">
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
    </div>
    <!-- score -->
    <div class="col-2  d-flex flex-column align-items-center justify-content-around">
      <p class=" m-0 placeholder rounded" style="width:2rem;"></p>
    </div>
    <!-- team 2 -->
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <div class=" img-circle-container d-flex justify-content-center  flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
    </div>
    <!-- botom bar -->
    <div class=" row p-0 g-0 my-2">
      <div class="col-4 p-0 m-0 mt-2" style="width: 33.3%;"></div>
      <div class=" col-4 text-center  p-0 m-0 mt-2   " style="width: 33.3%;">
        <span class="placeholder w-25 rounded" style="height: 20px"></span>
      </div>
      <div class=" col-4  text-end  p-0 m-0 pe-2 mt-2   " style="width: 33.3%; ">
        <span class="placeholder bg-danger w-25 rounded" style="height: 20px"></span>
      </div>
    </div>
  </div>
   <div class="row  mt-3 rounded text-white color-dark  placeholder-glow" style="height:168px;">
    <div class="col-12 p-0 d-flex justify-content-between rounded ">
      <div class="text-start d-flex p-1 rounded-start placeholder w-25"></div>
      <div class="text-end  placeholder rounded me-2 mt-1 " style="width: 7rem; height: 20px"></div>
    </div>
    <!-- team 1 -->
    <div class="col-5 mt-2 d-flex justify-content-end align-items-center ">
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
    </div>
    <!-- score -->
    <div class="col-2  d-flex flex-column align-items-center justify-content-around">
      <p class=" m-0 placeholder rounded" style="width:2rem;"></p>
    </div>
    <!-- team 2 -->
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <div class=" img-circle-container d-flex justify-content-center  flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
    </div>
    <!-- botom bar -->
    <div class=" row p-0 g-0 my-2">
      <div class="col-4 p-0 m-0 mt-2" style="width: 33.3%;"></div>
      <div class=" col-4 text-center  p-0 m-0 mt-2   " style="width: 33.3%;">
        <span class="placeholder w-25 rounded" style="height: 20px"></span>
      </div>
      <div class=" col-4  text-end  p-0 m-0 pe-2 mt-2   " style="width: 33.3%; ">
        <span class="placeholder bg-danger w-25 rounded" style="height: 20px"></span>
      </div>
    </div>
  </div>
   <div class="row  mt-3 rounded text-white color-dark  placeholder-glow" style="height:168px;">
    <div class="col-12 p-0 d-flex justify-content-between rounded ">
      <div class="text-start d-flex p-1 rounded-start placeholder w-25"></div>
      <div class="text-end  placeholder rounded me-2 mt-1 " style="width: 7rem; height: 20px"></div>
    </div>
    <!-- team 1 -->
    <div class="col-5 mt-2 d-flex justify-content-end align-items-center ">
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
    </div>
    <!-- score -->
    <div class="col-2  d-flex flex-column align-items-center justify-content-around">
      <p class=" m-0 placeholder rounded" style="width:2rem;"></p>
    </div>
    <!-- team 2 -->
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <div class=" img-circle-container d-flex justify-content-center  flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
    </div>
    <!-- botom bar -->
    <div class=" row p-0 g-0 my-2">
      <div class="col-4 p-0 m-0 mt-2" style="width: 33.3%;"></div>
      <div class=" col-4 text-center  p-0 m-0 mt-2   " style="width: 33.3%;">
        <span class="placeholder w-25 rounded" style="height: 20px"></span>
      </div>
      <div class=" col-4  text-end  p-0 m-0 pe-2 mt-2   " style="width: 33.3%; ">
        <span class="placeholder bg-danger w-25 rounded" style="height: 20px"></span>
      </div>
    </div>
  </div>
   <div class="row  mt-3 rounded text-white color-dark  placeholder-glow" style="height:168px;">
    <div class="col-12 p-0 d-flex justify-content-between rounded ">
      <div class="text-start d-flex p-1 rounded-start placeholder w-25"></div>
      <div class="text-end  placeholder rounded me-2 mt-1 " style="width: 7rem; height: 20px"></div>
    </div>
    <!-- team 1 -->
    <div class="col-5 mt-2 d-flex justify-content-end align-items-center ">
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
    </div>
    <!-- score -->
    <div class="col-2  d-flex flex-column align-items-center justify-content-around">
      <p class=" m-0 placeholder rounded" style="width:2rem;"></p>
    </div>
    <!-- team 2 -->
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <div class=" img-circle-container d-flex justify-content-center  flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
    </div>
    <!-- botom bar -->
    <div class=" row p-0 g-0 my-2">
      <div class="col-4 p-0 m-0 mt-2" style="width: 33.3%;"></div>
      <div class=" col-4 text-center  p-0 m-0 mt-2   " style="width: 33.3%;">
        <span class="placeholder w-25 rounded" style="height: 20px"></span>
      </div>
      <div class=" col-4  text-end  p-0 m-0 pe-2 mt-2   " style="width: 33.3%; ">
        <span class="placeholder bg-danger w-25 rounded" style="height: 20px"></span>
      </div>
    </div>
  </div>
   <div class="row  mt-3 rounded text-white color-dark  placeholder-glow" style="height:168px;">
    <div class="col-12 p-0 d-flex justify-content-between rounded ">
      <div class="text-start d-flex p-1 rounded-start placeholder w-25"></div>
      <div class="text-end  placeholder rounded me-2 mt-1 " style="width: 7rem; height: 20px"></div>
    </div>
    <!-- team 1 -->
    <div class="col-5 mt-2 d-flex justify-content-end align-items-center ">
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
    </div>
    <!-- score -->
    <div class="col-2  d-flex flex-column align-items-center justify-content-around">
      <p class=" m-0 placeholder rounded" style="width:2rem;"></p>
    </div>
    <!-- team 2 -->
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <div class=" img-circle-container d-flex justify-content-center  flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
    </div>
    <!-- botom bar -->
    <div class=" row p-0 g-0 my-2">
      <div class="col-4 p-0 m-0 mt-2" style="width: 33.3%;"></div>
      <div class=" col-4 text-center  p-0 m-0 mt-2   " style="width: 33.3%;">
        <span class="placeholder w-25 rounded" style="height: 20px"></span>
      </div>
      <div class=" col-4  text-end  p-0 m-0 pe-2 mt-2   " style="width: 33.3%; ">
        <span class="placeholder bg-danger w-25 rounded" style="height: 20px"></span>
      </div>
    </div>
  </div>
   <div class="row  mt-3 rounded text-white color-dark  placeholder-glow" style="height:168px;">
    <div class="col-12 p-0 d-flex justify-content-between rounded ">
      <div class="text-start d-flex p-1 rounded-start placeholder w-25"></div>
      <div class="text-end  placeholder rounded me-2 mt-1 " style="width: 7rem; height: 20px"></div>
    </div>
    <!-- team 1 -->
    <div class="col-5 mt-2 d-flex justify-content-end align-items-center ">
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
    </div>
    <!-- score -->
    <div class="col-2  d-flex flex-column align-items-center justify-content-around">
      <p class=" m-0 placeholder rounded" style="width:2rem;"></p>
    </div>
    <!-- team 2 -->
    <div class="col-5 d-flex justify-content-start align-items-center ">
      <div class=" img-circle-container d-flex justify-content-center  flex-column m-2 rounded-circle placeholder " style="width: 50px; height: 50px;"></div>
      <span class="team-name-resize mx-2 my-0 placeholder rounded " style="width: 10rem; height: 20px"></span>
    </div>
    <!-- botom bar -->
    <div class=" row p-0 g-0 my-2">
      <div class="col-4 p-0 m-0 mt-2" style="width: 33.3%;"></div>
      <div class=" col-4 text-center  p-0 m-0 mt-2   " style="width: 33.3%;">
        <span class="placeholder w-25 rounded" style="height: 20px"></span>
      </div>
      <div class=" col-4  text-end  p-0 m-0 pe-2 mt-2   " style="width: 33.3%; ">
        <span class="placeholder bg-danger w-25 rounded" style="height: 20px"></span>
      </div>
    </div>
  </div>
</div>

<!-- placeholder ends -->
      
      
      
      
        <div class="home-matches p-1">
        
        
            <matches-component data-mode="matchesPage"></matches-component>    
        </div>
      </div>



</div>





<div class="sidebar col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-2 ">



    <div class="sidebar-predictions home-components row m-2 ">
       
         <predictions-homecomponent></predictions-homecomponent>
        

    </div>
    <div class="sidebar-discord home-components row m-2 " >
        
            
            <div class="home-discord-template">
            <discord-template></discord-template>
        
        </div>

    </div>
    <div class="sidebar-bonus home-components row m-2 ">
        
           
            <div class="home-widget-bookmakers">
            <widget-bookmakers></widget-bookmakers>
        
        </div>
    </div>
</div>  
`;

MatchesContainer.reg('matches-container');
