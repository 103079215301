import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { MatchesComponent } from "../matches-component/matches-component";
import { PostBookmakersComponent } from "../postbookmakers-component/postbookmakers-component";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";
import { AuthorsComponent } from "../author-component/author-component";
import { NewsCarousel } from "../news-carousel/news-carousel";
import { NewsPost } from "../news-post/news-post";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";





const css = `
<style>
news-component article a:hover .card-title,
a:hover .card-title,
.card:hover .card-title {
    color: #B22A23 ;
}

.see-more-button:hover{
color: #B22A23;
cursor:pointer;
}
.go-back-button:hover{
color: #B22A23;
cursor:pointer;
}
.go-back-button2:hover{
color: #B22A23;
cursor:pointer;
}
.go-back-button3:hover{
color: #B22A23;
cursor:pointer;
}
 news-component {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

  news-component .container {
          
            border-radius: 8px;
            padding: 20px;
        }

      news-component .image-wrapper {
            position: relative;
            overflow: hidden;
            height: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio */
            transition: transform 0.3s ease;
        }

       news-component .image-wrapper img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.5s ease;
        }

       news-component .image-wrapper:hover img {
            transform: scale(1.1); /* Zoom on hover */
        }

       news-component .overlay-content {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 10px;
            background: rgba(0, 0, 0, 0.6);
            color: #fff;
            transition: background 0.3s ease;
        }

       news-component .overlay-content:hover {
            background: rgba(0, 0, 0, 0.8);
        }

       news-component .overlay-content h5, news-component .overlay-content h2 {
            margin: 0;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Limits to 2 lines */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

       news-component .overlay-content h5 {
            font-size: 0.9rem; /* Smaller font size for side articles */
        }

        /* Style for all <a> tags */
       news-component news-component a {
            text-decoration: none !important;
            color: #fff !important;
            transition: color 0.3s ease !important;
        }

    

       news-component .news-meta {
            font-size: 0.9rem;
            opacity: 0.8;
        }

        /* New styles for hobby section */
       news-component .big-post {
            margin-bottom: 20px;
        }

       news-component .big-post .card-title ,news-component .small-posts-3 .card-title {
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Limits to 2 lines for title */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 10px;
        }
      
       news-component .small-posts-3 .card-title{
          font-size: 0.85rem;
        }
          news-component .small-posts-3 .card-text{
          font-size: 0.75rem;
        }
       news-component .big-post .card-title a {
            font-size: 1.1rem; /* Adjust title font size */
            color: #fff; /* Ensure title link color is white */
        }
  

       news-component .author {
            color: #B22A23; /* Color for author name */
        }

      news-component  .big-post p {
            display: -webkit-box;
            -webkit-line-clamp: 3; /* Limits to 3 lines for content */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

       news-component .date {
            color: #ccc; /* Light color for date */
            margin-left: 5px; /* Space between author and date */
        }

        /* Change background color for card */
       news-component .card {
            border: none;
            background-color: #252E39; /* Moved to card */
            color: #fff; /* Ensures text is white */
        }

        /* Set text color for card-body */
       news-component .card-body {
            padding: 20px; /* Add padding for aesthetics */
            border-radius: 8px; /* Add border radius */
        }

        /* Make all images rounded */
       news-component .image-wrapper img {
            border-radius: 8px; /* Add rounding effect */
        }

     

news-component .author-link{
  color: #B22A23 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

news-component .news-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Center title and date for small posts */
news-component .small-post .card-title {
    font-size: 0.85rem; /* Smaller title size */
    display: block;
    text-align: left; /* Center the title */
    margin-bottom: 5px;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Limit to 2 lines */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 10px;
        }
}

news-component .small-post .news-date {
    display: block;
    text-align: left; /* Center the date */
    
    
}
news-component .news-date{
  color:grey;
   font-size: 0.75rem;
}

news-component .small-post .card-body {
    padding-top: 10px;
}
     /* Hover effect for article */
      .card:hover .card-title a{
    color: #B22A23 !important; /* Change title color on hover */
}
</style>

`

export class NewsComponent extends BaseComponent {

    initCallback() {

        RouterAssistant.checkForSingleContainer('news-component', NewsComponent.initiator);
        document.head.insertAdjacentHTML("beforeend", css)



    }

    static initiator() {
        NewsCarousel.getNews(() => {
            NewsComponent.updateNews(GameNavbar.getCurrentlyActivatedGameIfAny())
            if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) { //todo this is wrong , the gameNavBar needs to be called to ask whether a game filter is currently active!!!!
                console.debug("if", window.savedNews)
                //exists for case of no news per game
            } else {
                console.debug("else", window.savedNews)
                //exists for case of no news per game
            }
        });
        // GameNavbar.subscribeToGameFilterChanges('fromNewsPage', (activeGameFilterId) => {
        //     if (document.querySelectorAll('news-component').length !== 0) {// if our component exists on the page
        //         // NewsCarousel.populateNewsCarousel(activeGameFilterId); DO THINGS
        //         if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
        //             //exists for case of no news per game
        //         } else {
        //             //exists for case of no news per game
        //         }
        //     }
        // });

    }

    static updateNews(activeGameFilterId) {
        console.debug(activeGameFilterId)
        if (document.querySelectorAll('news-component').length !== 0) {
            const mainContainer = document.getElementById('theContainer');
            const subContainer = document.getElementById('theContainer2');
            const theButton = document.getElementById('theButton');
            const promoArray = {}
            const tournamentArray = {}
            const rosterArray = {}
            const gameplayArray = {}
            for (const [NewsPostId, NewsPostData] of Object.entries(window.savedNews)) {
                if (activeGameFilterId !== null && activeGameFilterId !== 'null' && NewsPostData.game_tag !== activeGameFilterId) continue
                if (NewsPostData.public_draft !== 'Public' || NewsPostData.is_page) continue

                // const greeklishUrl = NewsPostData.public_url_greeklish;
                const writtenAtTimestamp = NewsPostData.writtenAt;
                const writtenAtDate = new Date(writtenAtTimestamp.seconds * 1000 + writtenAtTimestamp.nanoseconds / 1000000);
                const formattedDate = writtenAtDate.toLocaleString(I18n.getUsersPreferredLanguage(), {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                });

                NewsPostData.writtenAtUse = formattedDate;


                function removeMarkdown(markdown) {
                    return markdown
                        // Remove headers
                        .replace(/^#{1,6}\s+/gm, '')
                        // Remove bold and italic
                        .replace(/(\*\*|__)(.*?)\1/g, '$2')
                        .replace(/(\*|_)(.*?)\1/g, '$2')
                        // Remove strikethrough
                        .replace(/~~(.*?)~~/g, '$1')
                        // Remove links
                        .replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1')
                        // Remove images
                        .replace(/!\[([^\]]*)\]\([^\)]+\)/g, '$1')
                        // Remove inline code
                        .replace(/`([^`]+)`/g, '$1')
                        // Remove blockquotes
                        .replace(/^>\s+/gm, '')
                        // Remove horizontal rules
                        .replace(/^(-{3,}|_{3,}|\*{3,})$/gm, '')
                        // Remove unordered lists
                        .replace(/^\s*[-+*]\s+/gm, '')
                        // Remove ordered lists
                        .replace(/^\s*\d+\.\s+/gm, '')
                        // Remove <a> and </a> tags
                        .replace(/<\/?a[^>]*>/g, '')
                        // Remove extra spaces
                        .replace(/\s{2,}/g, ' ')
                        // Trim the result
                        .trim();
                }
                NewsPostData.contentUse = {};
                NewsPostData.contentUse[I18n.getUsersPreferredLanguage()] = removeMarkdown(NewsPostData.content[I18n.getUsersPreferredLanguage()]);

                if (NewsPostData.game_tag === "lol") {
                    NewsPostData.gameTagUse = "League of Legends"
                } else if (NewsPostData.game_tag === "dota2") {
                    NewsPostData.gameTagUse = "Dota 2"
                } else if (NewsPostData.game_tag === "csgo") {
                    NewsPostData.gameTagUse = "CS2"
                } else if (NewsPostData.game_tag === "valorant") {
                    NewsPostData.gameTagUse = "Valorant"
                } else if (NewsPostData.game_tag === "overwatch") {
                    NewsPostData.gameTagUse = "Overwatch"
                } else {
                    NewsPostData.gameTagUse = NewsPostData.game_tag;
                }

                if (NewsPostData.newsHotBoolean === true) {
                    promoArray[NewsPostData.public_url_greeklish] = NewsPostData;
                }
                if (NewsPostData.newsFilter === 'Tournaments') {
                    tournamentArray[NewsPostData.public_url_greeklish] = NewsPostData;
                }
                if (NewsPostData.newsFilter === 'Rosters') {
                    rosterArray[NewsPostData.public_url_greeklish] = NewsPostData;
                }
                if (NewsPostData.newsFilter === 'Gameplay') {
                    gameplayArray[NewsPostData.public_url_greeklish] = NewsPostData;
                }


            }

            function sortArrayByDate(array) {
                return array.sort((a, b) => {
                    const dateA = new Date(a.writtenAtUse);
                    const dateB = new Date(b.writtenAtUse);
                    return dateB - dateA; // Sort from newer to older
                });
            }

            const sortedPromoArray = sortArrayByDate(Object.values(promoArray));
            const sortedTournamentArray = sortArrayByDate(Object.values(tournamentArray));
            const sortedRosterArray = sortArrayByDate(Object.values(rosterArray));
            const sortedGameplayArray = sortArrayByDate(Object.values(gameplayArray));

            console.debug(sortedPromoArray[1], sortedTournamentArray, sortedRosterArray, sortedGameplayArray)
            let rostersHtml = '', tournamentsHtml = '', promoHtml = '', gameplayHtml = '';

            sortedTournamentArray.forEach(entry => {
                tournamentsHtml += /*html*/ `       
                        <!-- Block Style 1: Big Post (Featured) -->
                        <a href="${window.location.origin}/s_news?article=${entry.public_url_greeklish}" newspage2-newsid="secondoption-${entry.public_url_greeklish}" class="big-post">
                            <article class="card card-full hover-a mb-4">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <!-- Thumbnail -->
                                        <div class="ratio_360-202 image-wrapper">
                                                <img width="360" height="202" src="${entry.article_photo}" class="img-fluid rounded" alt="">
                                        </div>
                                    </div>
                                    <div class="col-lg-9">
                                        <div class="card-body pt-3 pt-lg-0">
                                            <!-- Title -->
                                            <h2 class="card-title h1-sm h3-lg">
                                                ${entry.titles[I18n.getUsersPreferredLanguage()]}
                                            </h2>
                                            <div class="mb-2 text-muted small d-inline-flex">
                                                <!-- Author -->
                                                <span class="fw-bold d-none d-sm-inline me-1">
                                                    <div rel="author" class="author-link">${entry.linkedAuthor}</div>
                                                    <!-- Date -->
                                                </span>
                                                <time class="news-date" datetime="">${entry.writtenAtUse}</time>
                                                <!-- Comments -->
                                                <span title="0 Comment" class="float-end">
                                                    <span class="icon-comments"></span> <!-- FOR FUTURE TO DO COMMENTS-->
                                                </span>
                                            </div>
                                            <!-- Description -->
                                            <p class="big-post-description">${entry.contentUse[I18n.getUsersPreferredLanguage()]}}</p>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </a>
                `
            });

            sortedRosterArray.forEach(entry => {
                rostersHtml += /*html*/ `       
                        <!-- Block Style 1: Big Post (Featured) -->
                        <a href="${window.location.origin}/s_news?article=${entry.public_url_greeklish}" newspage2-newsid="${entry.public_url_greeklish}" class="big-post">
                            <article class="card card-full hover-a mb-4">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <!-- Thumbnail -->
                                        <div class="ratio_360-202 image-wrapper">
                                                <img width="360" height="202" src="${entry.article_photo}" class="img-fluid rounded" alt="">
                                        </div>
                                    </div>
                                    <div class="col-lg-9">
                                        <div class="card-body pt-3 pt-lg-0">
                                            <!-- Title -->
                                            <h2 class="card-title h1-sm h3-lg">
                                                ${entry.titles[I18n.getUsersPreferredLanguage()]}
                                            </h2>
                                            <div class="mb-2 text-muted small d-inline-flex">
                                                <!-- Author -->
                                                <span class="fw-bold d-none d-sm-inline me-1">
                                                    <div rel="author" class="author-link">${entry.linkedAuthor}</div>
                                                    <!-- Date -->
                                                </span>
                                                <time class="news-date" datetime="">${entry.writtenAtUse}</time>
                                                <!-- Comments -->
                                                <span title="0 Comment" class="float-end">
                                                    <span class="icon-comments"></span> <!-- FOR FUTURE TO DO COMMENTS-->
                                                </span>
                                            </div>
                                            <!-- Description -->
                                            <p class="big-post-description">${entry.contentUse[I18n.getUsersPreferredLanguage()]}}</p>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </a>
                `
            });

            sortedGameplayArray.forEach(entry => {
                gameplayHtml += /*html*/ `       
                        <!-- Block Style 1: Big Post (Featured) -->
                        <a href="${window.location.origin}/s_news?article=${entry.public_url_greeklish}" newspage2-newsid="${entry.public_url_greeklish}" class="big-post">
                            <article class="card card-full hover-a mb-4">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <!-- Thumbnail -->
                                        <div class="ratio_360-202 image-wrapper">
                                                <img width="360" height="202" src="${entry.article_photo}" class="img-fluid rounded" alt="">
                                        </div>
                                    </div>
                                    <div class="col-lg-9">
                                        <div class="card-body pt-3 pt-lg-0">
                                            <!-- Title -->
                                            <h2 class="card-title h1-sm h3-lg">
                                                ${entry.titles[I18n.getUsersPreferredLanguage()]}
                                            </h2>
                                            <div class="mb-2 text-muted small d-inline-flex">
                                                <!-- Author -->
                                                <span class="fw-bold d-none d-sm-inline me-1">
                                                    <div rel="author" class="author-link">${entry.linkedAuthor}</div>
                                                    <!-- Date -->
                                                </span>
                                                <time class="news-date" datetime="">${entry.writtenAtUse}</time>
                                                <!-- Comments -->
                                                <span title="0 Comment" class="float-end">
                                                    <span class="icon-comments"></span> <!-- FOR FUTURE TO DO COMMENTS-->
                                                </span>
                                            </div>
                                            <!-- Description -->
                                            <p class="big-post-description">${entry.contentUse[I18n.getUsersPreferredLanguage()]}}</p>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </a>
                `
            });



            const theHtml = /*html*/ `
                        <div class="row">
                        <!-- Left Column -->
                        <div class="col-lg-3">
                            <div class="row g-2">
                                <!-- First News Box -->
                                <div class="col-6 col-lg-12">
                                    <a href="${window.location.origin}/s_news?article=${sortedPromoArray[1].public_url_greeklish}" class="card text-white overflow-hidden" newspage-newsid="${sortedPromoArray[1].public_url_greeklish}">
                                        <div class="image-wrapper">
                                            <img src="${sortedPromoArray[1].article_photo}" alt="News Image" class="rounded">
                                            <div class="overlay-content">
                                                <div class="badge bg-warning text-dark">${sortedPromoArray[1].gameTagUse}</div>
                                                <h5 class="mt-2 card-title">
                                                    <div>${sortedPromoArray[1].titles[I18n.getUsersPreferredLanguage()]}</div>
                                                </h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <!-- Second News Box -->
                                <div class="col-6 col-lg-12">
                                    <a href="${window.location.origin}/s_news?article=${sortedPromoArray[2].public_url_greeklish}" class="card text-white overflow-hidden" newspage-newsid="${sortedPromoArray[2].public_url_greeklish}">
                                        <div class="image-wrapper">
                                            <img src="${sortedPromoArray[2].article_photo}" alt="News Image" class="rounded">
                                            <div class="overlay-content">
                                                <div class="badge bg-warning text-dark">${sortedPromoArray[2].gameTagUse}</div>
                                                <h5 class="mt-2 card-title">
                                                    <div>${sortedPromoArray[2].titles[I18n.getUsersPreferredLanguage()]}</div>
                                                </h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <!-- Center Column -->
                        <div class="col-lg-6">
                            <a href="${window.location.origin}/s_news?article=${sortedPromoArray[0].public_url_greeklish}" class="card text-white overflow-hidden" newspage-newsid="${sortedPromoArray[0].public_url_greeklish}">
                                <div class="image-wrapper">
                                            <img src="${sortedPromoArray[0].article_photo}" alt="News Image" class="rounded">
                                    <div class="overlay-content">
                                        <h2 class="fw-bold card-title">
                                            <div>${sortedPromoArray[0].titles[I18n.getUsersPreferredLanguage()]}</div>
                                        </h2>
                                        <div class="news-meta d-inline-flex">
                                            <span class="d-inline-flex ">by <strong><div class="text-white mx-1">${sortedPromoArray[0].linkedAuthor}</div></strong></span>
                                            <time datetime="2019-09-11" class="text-white">${sortedPromoArray[0].writtenAtUse}</time>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <!-- Right Column -->
                        <div class="col-lg-3">
                            <div class="row g-2">
                                <!-- Third News Box -->
                                <div class="col-6 col-lg-12">
                                    <a href="${window.location.origin}/s_news?article=${sortedPromoArray[3].public_url_greeklish}" class="card text-white overflow-hidden" newspage-newsid="${sortedPromoArray[3].public_url_greeklish}">
                                        <div class="image-wrapper">
                                            <img src="${sortedPromoArray[3].article_photo}" alt="News Image" class="rounded">
                                            <div class="overlay-content">
                                                <div class="badge bg-warning text-dark">${sortedPromoArray[3].gameTagUse}</div>
                                                <h5 class="mt-2 card-title">
                                                    <div>${sortedPromoArray[3].titles[I18n.getUsersPreferredLanguage()]}</div>
                                                </h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <!-- Fourth News Box -->
                                <div class="col-6 col-lg-12">
                                    <a href="${window.location.origin}/s_news?article=${sortedPromoArray[4].public_url_greeklish}" class="card text-white overflow-hidden" newspage-newsid="${sortedPromoArray[4].public_url_greeklish}">
                                        <div class="image-wrapper">
                                            <img src="${sortedPromoArray[4].article_photo}" alt="News Image" class="rounded">
                                            <div class="overlay-content">
                                                <div class="badge bg-warning text-dark">${sortedPromoArray[4].gameTagUse}</div>
                                                <h5 class="mt-2 card-title">
                                                    <div>${sortedPromoArray[4].titles[I18n.getUsersPreferredLanguage()]}</div>
                                                </h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- New Row with col-8 and col-4 -->
    <div class="row mt-4">
        <!-- col-8 for News Section -->
        <div class="col-lg-8 hobby-section">
            <div class="block-area">
                <div class="block-title-4">
                    <h4 class="h5 title-arrow news-section"><span>${I18n.translateString('newsTranslations.rosterNews')}</span><span class="rosters-button see-more-button">${I18n.translateString('newsTranslations.seeMore')}</span></h4> 
                </div>

                <!-- Block Style 1: Big Post (Featured) -->
              <a href="${window.location.origin}/s_news?article=${sortedRosterArray[0].public_url_greeklish}" class="big-post" newspage-newsid="${sortedRosterArray[0].public_url_greeklish}">
    <article class="card card-full hover-a mb-4">
        <div class="row">
            <div class="col-lg-6">
                <!-- Thumbnail -->
                <div class="ratio_360-202 image-wrapper">
                    <div>
                        <img width="360" height="202" src="${sortedRosterArray[0].article_photo}" class="img-fluid rounded" alt="Featured Article Image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card-body pt-3 pt-lg-0">
                    <!-- Title -->
                    <h2 class="card-title h1-sm h3-lg">
                        ${sortedRosterArray[0].titles[I18n.getUsersPreferredLanguage()]}
                    </h2>
                    <div class="mb-2 text-muted small d-inline-flex">
                        <!-- Author -->
                        <span class="fw-bold d-none d-sm-inline me-1">
                            <div rel="author" class="author-link">${sortedRosterArray[0].linkedAuthor}</div>
                        </span>
                        <!-- Date -->
                        <time class="news-date" datetime="">${sortedRosterArray[0].writtenAtUse}</time>
                        <!-- Comments -->
                        <span title="0 Comment" class="float-end">
                            <span class="icon-comments"></span> <!-- Logika set gia viewers(apo shadow) SOON TM -->
                        </span>
                    </div>
                    <!-- Description -->
                    <p>${sortedRosterArray[0].contentUse[I18n.getUsersPreferredLanguage()]}</p>
                </div>
            </div>
        </div>
    </article>
</a>

                <!-- Small Posts Grid (2x2 Layout for the Remaining Articles) -->
                <div class="small-post">
                    <div class="row">
                        <!-- First Small Post -->
                        <article class="col-lg-6">
                            <div class="card card-full hover-a mb-4">
                                <a href="${window.location.origin}/s_news?article=${sortedRosterArray[1].public_url_greeklish}" class="row" newspage-newsid="${sortedRosterArray[1].public_url_greeklish}">
                                    <!-- Thumbnail -->
                                    <div class="col-3 col-md-4 pe-2 pe-md-0">
                                        <div class="ratio_180-123 image-wrapper">
                                                <img width="115" height="80" src="${sortedRosterArray[1].article_photo}" class="img-fluid rounded" alt="First Small Article Image">
                                        </div>
                                    </div>
                                    <div class="col-9 col-md-8">
                                        <div class="card-body ">
                                            <!-- Title -->
                                            <h3 class="card-title h6 h5-sm h6-lg">
                                                ${sortedRosterArray[1].titles[I18n.getUsersPreferredLanguage()]}
                                            </h3>
                                            <!-- Date -->
                                            <div class="small text-muted">
                                                <time class="news-date" datetime="">${sortedRosterArray[1].writtenAtUse}</time>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </article>

                        <!-- Second Small Post -->
                        <article class="col-lg-6">
                            <div class="card card-full hover-a mb-4">
                                <a href="${window.location.origin}/s_news?article=${sortedRosterArray[2].public_url_greeklish}" class="row" newspage-newsid="${sortedRosterArray[2].public_url_greeklish}">
                                    <!-- Thumbnail -->
                                    <div class="col-3 col-md-4 pe-2 pe-md-0">
                                        <div class="ratio_180-123 image-wrapper">
                                                <img width="115" height="80" src="${sortedRosterArray[2].article_photo}" class="img-fluid rounded" alt="Second Small Article Image">
                                        </div>
                                    </div>
                                    <div class="col-9 col-md-8">
                                        <div class="card-body ">
                                            <!-- Title -->
                                            <h3 class="card-title h6 h5-sm h6-lg">
                                                ${sortedRosterArray[2].titles[I18n.getUsersPreferredLanguage()]}
                                            </h3>
                                            <!-- Date -->
                                            <div class="small text-muted">
                                                <time class="news-date" datetime="">${sortedRosterArray[2].writtenAtUse}</time>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </article>

                        <!-- Third Small Post -->
                        <article class="col-lg-6">
                            <div class="card card-full hover-a mb-4">
                                <a href="${window.location.origin}/s_news?article=${sortedRosterArray[3].public_url_greeklish}" class="row" newspage-newsid="${sortedRosterArray[3].public_url_greeklish}">
                                    <!-- Thumbnail -->
                                    <div class="col-3 col-md-4 pe-2 pe-md-0">
                                        <div class="ratio_180-123 image-wrapper">
                                                <img width="115" height="80" src="${sortedRosterArray[3].article_photo}" class="img-fluid rounded" alt="Third Small Article Image">
                                        </div>
                                    </div>
                                    <div class="col-9 col-md-8">
                                        <div class="card-body ">
                                            <!-- Title -->
                                            <h3 class="card-title h6 h5-sm h6-lg">
                                                ${sortedRosterArray[3].titles[I18n.getUsersPreferredLanguage()]}
                                            </h3>
                                            <!-- Date -->
                                            <div class="small text-muted">
                                                <time class="news-date" datetime="">${sortedRosterArray[3].writtenAtUse}</time>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </article>

                        <!-- Fourth Small Post -->
                        <article class="col-lg-6">
                            <div class="card card-full hover-a mb-4">
                                <a href="${window.location.origin}/s_news?article=${sortedRosterArray[4].public_url_greeklish}" class="row" newspage-newsid="${sortedRosterArray[4].public_url_greeklish}">
                                    <!-- Thumbnail -->
                                    <div class="col-3 col-md-4 pe-2 pe-md-0">
                                        <div class="ratio_180-123 image-wrapper">
                                                <img width="115" height="80" src="${sortedRosterArray[4].article_photo}" class="img-fluid rounded" alt="Fourth Small Article Image">
                                        </div>
                                    </div>
                                    <div class="col-9 col-md-8">
                                        <div class="card-body ">
                                            <!-- Title -->
                                            <h3 class="card-title h6 h5-sm h6-lg">
                                                ${sortedRosterArray[4].titles[I18n.getUsersPreferredLanguage()]}
                                            </h3>
                                            <!-- Date -->
                                            <div class="small text-muted">
                                                <time class="news-date" datetime="">${sortedRosterArray[4].writtenAtUse}</time>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </article>

                    </div>
                </div>  
            </div>
        


            <!-- Tournaments Section -->
<div class="block-area small-posts-3">
    <div class="block-title-4">
        <h4 class="h5 title-arrow news-section"><span>${I18n.translateString('newsTranslations.tournamentNews')}</span><span class="tournaments-button see-more-button">${I18n.translateString('newsTranslations.seeMore')}</span></h4>
    </div>

    <!-- First Row of 3 Articles -->
<div class="row">
    <article class="col-6 col-lg-4">
        <a href="${window.location.origin}/s_news?article=${sortedTournamentArray[0].public_url_greeklish}" newspage-newsid="${sortedTournamentArray[0].public_url_greeklish}" class="card card-full hover-a mb-4">
            <!--thumbnail-->
            <div class="ratio_230-129 image-wrapper">
                    <img width="230" height="129" src="${sortedTournamentArray[0].article_photo}" class="img-fluid lazy">
            </div>
            <div class="card-body">
                <div class="card-text mb-2 text-muted small">
                    <span class="fw-bold d-none d-sm-inline-flex me-1 ">
                        <div rel="author" class="author-link">${sortedTournamentArray[0].linkedAuthor} <time class="news-date ms-1" datetime="">${sortedTournamentArray[0].writtenAtUse}</time></div>
                    </span>
                    
                </div>
                <h2 class="card-title h6">
                   ${sortedTournamentArray[0].titles[I18n.getUsersPreferredLanguage()]}
                </h2>
            </div>
        </a>
    </article>

    <article class="col-6 col-lg-4">
        <a href="${window.location.origin}/s_news?article=${sortedTournamentArray[1].public_url_greeklish}" newspage-newsid="${sortedTournamentArray[1].public_url_greeklish}" class="card card-full hover-a mb-4">
            <!--thumbnail-->
            <div class="ratio_230-129 image-wrapper">
                    <img width="230" height="129" src="${sortedTournamentArray[1].article_photo}" class="img-fluid lazy">
            </div>
            <div class="card-body">
                <div class="card-text mb-2 text-muted small">
                    <span class="fw-bold d-none d-sm-inline-flex me-1 ">
                        <div rel="author" class="author-link">${sortedTournamentArray[1].linkedAuthor} <time class="news-date ms-1" datetime="">${sortedTournamentArray[1].writtenAtUse}</time></div>
                    </span>
                    
                </div>
                <h2 class="card-title h6">
                   ${sortedTournamentArray[1].titles[I18n.getUsersPreferredLanguage()]}
                </h2>
            </div>
        </a>
    </article>

    <article class="col-6 col-lg-4">
        <a href="${window.location.origin}/s_news?article=${sortedTournamentArray[2].public_url_greeklish}" newspage-newsid="${sortedTournamentArray[2].public_url_greeklish}" class="card card-full hover-a mb-4">
            <!--thumbnail-->
            <div class="ratio_230-129 image-wrapper">
                    <img width="230" height="129" src="${sortedTournamentArray[2].article_photo}" class="img-fluid lazy">
            </div>
            <div class="card-body">
                <div class="card-text mb-2 text-muted small">
                    <span class="fw-bold d-none d-sm-inline-flex me-1 ">
                        <div rel="author" class="author-link">${sortedTournamentArray[2].linkedAuthor} <time class="news-date ms-1" datetime="">${sortedTournamentArray[2].writtenAtUse}</time></div>
                    </span>
                    
                </div>
                <h2 class="card-title h6">
                   ${sortedTournamentArray[2].titles[I18n.getUsersPreferredLanguage()]}
                </h2>
            </div>
        </a>
    </article>

    <article class="col-6 col-lg-4">
        <a href="${window.location.origin}/s_news?article=${sortedTournamentArray[3].public_url_greeklish}" newspage-newsid="${sortedTournamentArray[3].public_url_greeklish}" class="card card-full hover-a mb-4">
            <!--thumbnail-->
            <div class="ratio_230-129 image-wrapper">
                    <img width="230" height="129" src="${sortedTournamentArray[3].article_photo}" class="img-fluid lazy">
            </div>
            <div class="card-body">
                <div class="card-text mb-2 text-muted small">
                    <span class="fw-bold d-none d-sm-inline-flex me-1 ">
                        <div rel="author" class="author-link">${sortedTournamentArray[3].linkedAuthor} <time class="news-date ms-1" datetime="">${sortedTournamentArray[3].writtenAtUse}</time></div>
                    </span>
                    
                </div>
                <h2 class="card-title h6">
                   ${sortedTournamentArray[3].titles[I18n.getUsersPreferredLanguage()]}
                </h2>
            </div>
        </a>
    </article>

    <article class="col-6 col-lg-4">
        <a href="${window.location.origin}/s_news?article=${sortedTournamentArray[4].public_url_greeklish}" newspage-newsid="${sortedTournamentArray[4].public_url_greeklish}" class="card card-full hover-a mb-4">
            <!--thumbnail-->
            <div class="ratio_230-129 image-wrapper">
                    <img width="230" height="129" src="${sortedTournamentArray[4].article_photo}" class="img-fluid lazy">
            </div>
            <div class="card-body">
                <div class="card-text mb-2 text-muted small">
                    <span class="fw-bold d-none d-sm-inline-flex me-1 ">
                        <div rel="author" class="author-link">${sortedTournamentArray[4].linkedAuthor} <time class="news-date ms-1" datetime="">${sortedTournamentArray[4].writtenAtUse}</time></div>
                    </span>
                    
                </div>
                <h2 class="card-title h6">
                   ${sortedTournamentArray[4].titles[I18n.getUsersPreferredLanguage()]}
                </h2>
            </div>
        </a>
    </article>

    <article class="col-6 col-lg-4">
        <a href="${window.location.origin}/s_news?article=${sortedTournamentArray[5].public_url_greeklish}" newspage-newsid="${sortedTournamentArray[5].public_url_greeklish}" class="card card-full hover-a mb-4">
            <!--thumbnail-->
            <div class="ratio_230-129 image-wrapper">
                    <img width="230" height="129" src="${sortedTournamentArray[5].article_photo}" class="img-fluid lazy">
            </div>
            <div class="card-body">
                <div class="card-text mb-2 text-muted small">
                    <span class="fw-bold d-none d-sm-inline-flex me-1 ">
                        <div rel="author" class="author-link">${sortedTournamentArray[5].linkedAuthor} <time class="news-date ms-1" datetime="">${sortedTournamentArray[5].writtenAtUse}</time></div>
                    </span>
                    
                </div>
                <h2 class="card-title h6">
                   ${sortedTournamentArray[5].titles[I18n.getUsersPreferredLanguage()]}
                </h2>
            </div>
        </a>
    </article>
</div>
</div>
</div>
        
        <!-- col-4 for Gameplay -->
<div class="col-lg-4 additional-content">
    <aside id="bootnews_latestside-2" class="widget widget_categories widget_categories_custom">
        <div class="block-title-4">
            <h4 class="h5 title-arrow news-section"><span>${I18n.translateString('newsTranslations.gameNews')}</span><span class="gameplay-button see-more-button">${I18n.translateString('newsTranslations.seeMore')}</span></h4>
        </div>
        
        <!-- Big Post -->
        <div class="big-post">
        <a href="${window.location.origin}/s_news?article=${sortedGameplayArray[0].public_url_greeklish}" newspage-newsid="${sortedGameplayArray[0].public_url_greeklish}" class="card card-full hover-a mb-4">
                <!-- Thumbnail -->
                <div class="ratio_360-202 image-wrapper">
                        <img width="360" height="202" src="${sortedGameplayArray[0].article_photo}" class="img-fluid lazy wp-post-image entered loaded" loading="lazy">
                </div>
                <!-- Card Body -->
                <div class="card-body">
                    <h2 class="card-title h1-sm h3-md">
                        ${sortedGameplayArray[0].titles[I18n.getUsersPreferredLanguage()]}
                    </h2>
                    <div class="card-text text-muted small mb-2">
                        <span class="d-none d-sm-inline-flex fw-bold me-1">
                            <div class="author-link" rel="author">${sortedGameplayArray[0].linkedAuthor}</div> <time class="news-date ms-1" datetime="">${sortedGameplayArray[0].writtenAtUse}</time>
                        </span>        
                    </div>
                    <p class="card-text">${sortedGameplayArray[0].contentUse[I18n.getUsersPreferredLanguage()]}</p>
                </div>
            </a>
        </div>
        
        <!-- Small Posts -->
        <div class="small-post">
            <!-- First Small Post -->
            <article class="card card-full hover-a mb-4">
                <a href="${window.location.origin}/s_news?article=${sortedGameplayArray[1].public_url_greeklish}" newspage-newsid="${sortedGameplayArray[1].public_url_greeklish}" class="row">
                    <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                                <img width="110" height="77" src="${sortedGameplayArray[1].article_photo}" class="img-fluid lazy wp-post-image entered loaded">
                        </div>
                    </div>
                    <div class="col-9 col-md-8">
                        <div class="card-body">
                            <h3 class="card-title h6 h5-sm h6-md">
                                ${sortedGameplayArray[1].titles[I18n.getUsersPreferredLanguage()]}
                            </h3>
                            <div class="card-text small text-muted">
                                <time class="news-date" datetime="">${sortedGameplayArray[1].writtenAtUse}</time>
                            </div>
                        </div>
                    </div>
                </a>
            </article>

            <!-- Second Small Post -->
            <article class="card card-full hover-a mb-4">
                <a href="${window.location.origin}/s_news?article=${sortedGameplayArray[2].public_url_greeklish}" newspage-newsid="${sortedGameplayArray[2].public_url_greeklish}" class="row">
                    <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                                <img width="110" height="77" src="${sortedGameplayArray[2].article_photo}" class="img-fluid lazy wp-post-image entered loaded">
                        </div>
                    </div>
                    <div class="col-9 col-md-8">
                        <div class="card-body">
                            <h3 class="card-title h6 h5-sm h6-md">
                                ${sortedGameplayArray[2].titles[I18n.getUsersPreferredLanguage()]}
                            </h3>
                            <div class="card-text small text-muted">
                                <time class="news-date" datetime="">${sortedGameplayArray[2].writtenAtUse}</time>
                            </div>
                        </div>
                    </div>
                </a>
            </article>

            <!-- Third Small Post -->
            <article class="card card-full hover-a mb-4">
                <a href="${window.location.origin}/s_news?article=${sortedGameplayArray[3].public_url_greeklish}" newspage-newsid="${sortedGameplayArray[3].public_url_greeklish}" class="row">
                    <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                                <img width="110" height="77" src="${sortedGameplayArray[3].article_photo}" class="img-fluid lazy wp-post-image entered loaded">
                        </div>
                    </div>
                    <div class="col-9 col-md-8">
                        <div class="card-body">
                            <h3 class="card-title h6 h5-sm h6-md">
                                ${sortedGameplayArray[3].titles[I18n.getUsersPreferredLanguage()]}
                            </h3>
                            <div class="card-text small text-muted">
                                <time class="news-date" datetime="">${sortedGameplayArray[3].writtenAtUse}</time>
                            </div>
                        </div>
                    </div>
                </a>
            </article>
        </div>
    </aside>
  <aside id="bootnews_social-1" class="widget widget_categories widget_categories_custom">
        <div class="block-title-4">
            <h4 class="h5 title-arrow"><span>Social Network</span></h4>
        </div>
        <ul class="list-unstyled side-link" style="padding: 15px; border-radius: 8px;">
            <li class="hover-facebook">
                <a class="d-block float-start h5 mb-3 w-50" rel="noopener" href="${window.globalSocials.facebook}" target="_blank">
                    <i class="bi bi-facebook"></i>
                    <span class="ms-2">Facebook</span>
                </a>
            </li>
            <li class="hover-twitter">
                <a class="d-block float-start h5 mb-3 w-50" rel="noopener" href="${window.globalSocials.twitter}" target="_blank">
                    <i class="bi bi-twitter"></i>
                    <span class="ms-2">Twitter</span>
                </a>
            </li>
            <li class="hover-instagram">
                <a class="d-block float-start h5 mb-3 w-50" rel="noopener" href="${window.globalSocials.instagram}" target="_blank">
                    <i class="bi bi-instagram"></i>
                    <span class="ms-2">Instagram</span>
                </a>
            </li>
            <li class="hover-tiktok">
                <a class="d-block float-start h5 mb-3 w-50" rel="noopener" href="${window.globalSocials.tiktok}" target="_blank">
                    <i class="bi bi-tiktok"></i>
                    <span class="ms-2">TikTok</span>
                </a>
            </li>
            <li class="hover-youtube">
                <a class="d-block float-start h5 mb-3 w-50" rel="noopener" href="${window.globalSocials.youtube}" target="_blank">
                    <i class="bi bi-youtube"></i>
                    <span class="ms-2">Youtube</span>
                </a>
            </li>
            <li class="hover-twitch">
                <a class="d-block float-start h5 mb-3 w-50" rel="noopener" href="${window.globalSocials.twitch}" target="_blank">
                    <i class="bi bi-twitch"></i>
                    <span class="ms-2">Twitch</span>
                </a>
            </li>
            <li class="hover-discord">
                <a class="d-block float-start h5 mb-3 w-50" rel="noopener" href="${window.globalSocials.discord}" target="_blank">
                    <i class="bi bi-discord"></i>
                    <span class="ms-2">Discord</span>
                </a>
            </li>
        </ul>
        <div class="gap-05"></div>
    </aside>
</div>
                    `

            let theSubHtml = /*html*/ `
 <div class="rostersNews-container" style="display: none;">
                <div class="col-lg-12 hobby-section">
                    <div class="block-area">
                        <div class="block-title-4 d-flex justify-content-between align-content-center align-items-center">
                            <h1 class="h1 title-arrow"><span>${I18n.translateString('newsTranslations.rosterNews')}</span></h1>
                            <span class="go-back-button">${I18n.translateString('newsTranslations.goBack')}</span>
                        </div>
                        ${rostersHtml}
                    </div>
                </div>
            </div>
            <div class="tournamentNews-container" style="display: none;">
                <div class="col-lg-12 hobby-section">
                    <div class="block-area">
                         <div class="block-title-4 d-flex justify-content-between align-content-center align-items-center">
                            <h1 class="h1 title-arrow"><span>${I18n.translateString('newsTranslations.tournamentNews')}</span></h1>
                            <span class="go-back-button2">${I18n.translateString('newsTranslations.goBack')}</span>
                        </div>
                        ${tournamentsHtml}
                    </div>
                </div>
            </div>
            <div class="gameplayNews-container" style="display: none;">
                <div class="col-lg-12 hobby-section">
                    <div class="block-area">
                         <div class="block-title-4 d-flex justify-content-between align-content-center align-items-center">
                            <h1 class="h1 title-arrow"><span>${I18n.translateString('newsTranslations.gameNews')}</span></h1>
                            <span class="go-back-button3">${I18n.translateString('newsTranslations.goBack')}</span>
                        </div>
                        ${gameplayHtml}
                    </div>
                </div>
            </div>
            
`;

            console.debug(mainContainer)
            mainContainer.insertAdjacentHTML('beforeend', /*html*/`
                		${theHtml}
                `);
            subContainer.insertAdjacentHTML('beforeend', /*html*/`
                        ${theSubHtml}
                `);


            const elements = document.querySelectorAll('[newspage-newsid]');
            const elements2 = document.querySelectorAll('[newspage2-newsid]');
            elements.forEach(element => {
                const newsId = element.getAttribute('newspage-newsid');
                element.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
                    RouterAssistant.redirect('s_news', {
                        article: newsId
                    })
                }))
            });
            elements2.forEach(element => {
                const newsId = element.getAttribute('newspage2-newsid');
                const alteredNewsId = newsId.replace('secondoption-', '');
                element.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
                    RouterAssistant.redirect('s_news', {
                        article: alteredNewsId
                    })
                }))
            });


            const button2 = document.querySelector('.rosters-button');
            const button3 = document.querySelector('.tournaments-button');
            const button4 = document.querySelector('.gameplay-button');

            button2.addEventListener('click', function () {
                const thecontainer = document.getElementById('theContainer');
                const container = document.querySelector('.rostersNews-container');
                const container2 = document.querySelector('.tournamentNews-container');
                const container3 = document.querySelector('.gameplayNews-container');
                const containerSub = document.getElementById('theContainer2');
                if (thecontainer && container) {
                    container.style.display = '';
                    container2.style.display = 'none';
                    container3.style.display = 'none';
                    containerSub.style.display = '';
                    thecontainer.style.display = 'none';
                    document.getElementById('gameFiltersContainer').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }
            });
            button3.addEventListener('click', function () {
                const thecontainer = document.getElementById('theContainer');
                const container = document.querySelector('.tournamentNews-container');
                const container2 = document.querySelector('.rostersNews-container');
                const container3 = document.querySelector('.gameplayNews-container');
                const containerSub = document.getElementById('theContainer2');
                if (thecontainer && container) {
                    container.style.display = '';
                    container2.style.display = 'none';
                    container3.style.display = 'none';
                    containerSub.style.display = '';
                    thecontainer.style.display = 'none';
                    document.getElementById('gameFiltersContainer').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }
            });
            button4.addEventListener('click', function () {
                const thecontainer = document.getElementById('theContainer');
                const container = document.querySelector('.gameplayNews-container');
                const container2 = document.querySelector('.tournamentNews-container');
                const container3 = document.querySelector('.rostersNews-container');
                const containerSub = document.getElementById('theContainer2');
                if (thecontainer && container) {
                    container.style.display = '';
                    container2.style.display = 'none';
                    container3.style.display = 'none';
                    containerSub.style.display = '';
                    thecontainer.style.display = 'none';
                    document.getElementById('gameFiltersContainer').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }
            });
            document.querySelector('.go-back-button').addEventListener('click', function () {
                const thecontainer = document.getElementById('theContainer');
                const container = document.getElementById('theContainer2');
                if (thecontainer && container) {
                    container.style.display = 'none';
                    thecontainer.style.display = '';
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            });
            document.querySelector('.go-back-button2').addEventListener('click', function () {
                const thecontainer = document.getElementById('theContainer');
                const container = document.getElementById('theContainer2');
                if (thecontainer && container) {
                    container.style.display = 'none';
                    thecontainer.style.display = '';
                    document.getElementById('gameFiltersContainer').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }
            });
            document.querySelector('.go-back-button3').addEventListener('click', function () {
                const thecontainer = document.getElementById('theContainer');
                const container = document.getElementById('theContainer2');
                if (thecontainer && container) {
                    container.style.display = 'none';
                    thecontainer.style.display = '';
                    document.getElementById('gameFiltersContainer').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }
            });


        }


        // console.log(window.savedNews)

    }

}

NewsComponent.template = /*html*/ `
<div id="theContainer" class="container"></div>
<div id="theContainer2" class="container"></div>
`;

NewsComponent.reg('news-component');

