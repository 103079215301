import { BaseComponent } from "@symbiotejs/symbiote";
import { MainNavbar } from "../main-navbar/main-navbar";
import { GameNavbar } from "../game-navbar/game-navbar";
import { FooterContainer } from "../footer-container/footer-container";
import { LivestreamerComponent } from "../livestreamer-component/livestreamer-component";
import { ScrollToTop } from "../scrolltotop-component/scrolltotop-component";
import { I18n } from "../../utilities/i18n/i18n";

const desktopBannersPerLanguage = {}
const desktopBannersRedirectionsPerLanguage = {}

desktopBannersPerLanguage['el_desktop_left'] = require(`./assets/elLeft.webp`)
desktopBannersPerLanguage['el_desktop_right'] = require(`./assets/elRight.webp`)
desktopBannersPerLanguage['en_desktop_left'] = require(`./assets/enLeft.webp`)
desktopBannersPerLanguage['en_desktop_right'] = require(`./assets/enRight.webp`)

desktopBannersRedirectionsPerLanguage['el_desktop_left'] = `${window.globalSocials.discord}`
desktopBannersRedirectionsPerLanguage['el_desktop_right'] = `${window.globalSocials.discord}`
desktopBannersRedirectionsPerLanguage['en_desktop_left'] = `${window.globalSocials.discord}`
desktopBannersRedirectionsPerLanguage['en_desktop_right'] = `${window.globalSocials.discord}`


const css = `
    <style>
        #gameFiltersContainer{
            overflow: hidden;
        }
            grid-orchestrator .side-advertisement{
                position: fixed;
            }
            grid-orchestrator .side-advertisement:hover{
cursor: pointer
            }
            .side-advertisement{
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            }
        @media screen and (orientation:portrait) {
            grid-orchestrator .side-advertisement{
                display: none;
            }
        }
        @media screen and (orientation:landscape) {
         
        }
    </style>
`

export class GridOrchestrator extends BaseComponent {
    // for the grid orchestrator to work, the router has been edited so that the barba-container get
    // the class 'row g-0' appended to it every time it changes because it is not feasible otherwise

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        window.addEventListener('resize', GridOrchestrator.performOrientationChangeActions);
        GridOrchestrator.performOrientationChangeActions();
        document.getElementsByTagName('grid-orchestrator')[0].classList.add('container')
        document.getElementsByTagName('grid-orchestrator')[0].classList.add('m-0')
        document.getElementsByTagName('grid-orchestrator')[0].classList.add('p-0')
        addEventListener("scroll", GridOrchestrator.checkForFooterMagic);
        GridOrchestrator.setInitialMaxHeightToGameFiltersContainer()
        GridOrchestrator.showAppropriateBannersBasedOnLanguage()
    }

    static initiate() {
        document.body.insertAdjacentHTML('afterbegin', '<grid-orchestrator></grid-orchestrator>')
        document.querySelectorAll('.side-advertisement').forEach(el => {
            el.addEventListener('click', function (evt) {
                if (evt.target.getAttribute('data-advert') === 'left')
                    window.open(desktopBannersRedirectionsPerLanguage[I18n.translateString('banners.desktopLeft')], 'leftFromMyEsports').focus()
                else
                    window.open(desktopBannersRedirectionsPerLanguage[I18n.translateString('banners.desktopRight')], 'rightFromMyEsports').focus()
            })
        })
    }

    static showAppropriateBannersBasedOnLanguage() {
        document.querySelector('[data-advert="left"]').style.backgroundImage = 'url("' + desktopBannersPerLanguage[I18n.translateString('banners.desktopLeft')] + '")';
        document.querySelector('[data-advert="right"]').style.backgroundImage = 'url("' + desktopBannersPerLanguage[I18n.translateString('banners.desktopRight')] + '")';
    }

    static performOrientationChangeActions() {
        document.querySelectorAll('.side-advertisement').forEach(el => {
            el.style.top = `${document.getElementById('menusWrapper').offsetHeight}px`
            el.style.maxHeight = `${window.innerHeight - document.getElementById('menusWrapper').offsetHeight - 10}px`
            el.style.minHeight = `${window.innerHeight - document.getElementById('menusWrapper').offsetHeight - 10}px`
        })
        if (window.innerHeight > window.innerWidth) {
            document.getElementById('contentColumn').classList.remove('col-10')
            document.getElementById('contentColumn').classList.remove('offset-1')
            document.getElementById('contentColumn').classList.add('col-12')
            document.querySelectorAll('side-advertisement').forEach(el => {
                el.classList.add('d-none')
            })
            document.getElementById('outsideMenus').style.marginTop = `0px`
            document.getElementById('outsideMenus').style.marginBottom = ``
            document.getElementById('outsideMenus').style.maxHeight = `${window.innerHeight - document.getElementById('menusWrapper').offsetHeight}px`
            document.getElementById('outsideMenus').style.overflowY = `scroll`
        } else {
            document.getElementById('contentColumn').classList.add('col-10')
            document.getElementById('contentColumn').classList.add('offset-1')
            document.getElementById('contentColumn').classList.remove('col-12')
            document.querySelectorAll('side-advertisement').forEach(el => {
                el.style.display = ''
            })
            document.getElementById('outsideMenus').style.marginTop = `${document.getElementById('menusWrapper').offsetHeight}px`
            document.getElementById('outsideMenus').style.marginBottom = `0px`
            document.getElementById('outsideMenus').style.maxHeight = ``
            document.getElementById('outsideMenus').style.overflowY = ``

        }
        //document.querySelector("grid-orchestrator").insertAdjacentHTML("afterbegin", "<scrolltotop-component></scrolltotop-component>")
    }

    static setInitialMaxHeightToGameFiltersContainer() {
        document.getElementById('gameFiltersContainer').style.maxHeight = `${document.getElementById('gameFiltersContainer').scrollHeight}px`
    }

    static checkForFooterMagic(evt) {
        const footerIsVisible = document.getElementById('footerContainer').getBoundingClientRect().y < window.innerHeight
        const advertMarginFromTop = document.getElementById('outsideMenus').getBoundingClientRect().height - document.querySelector('.side-advertisement').getBoundingClientRect().height - document.getElementById('footerContainer').getBoundingClientRect().height - 10

        if (footerIsVisible) {
            document.querySelectorAll('.side-advertisement').forEach(el => {
                el.style.position = 'absolute'
                el.style.marginTop = `${advertMarginFromTop}px`
            })
        } else {
            document.querySelectorAll('.side-advertisement').forEach(el => {
                el.style.position = 'fixed'
                el.style.marginTop = ''
            })
        }
    }

    static activateGameFilters() {
        document.getElementById('gameFiltersContainer').style.maxHeight = ''
        GridOrchestrator.performOrientationChangeActions()
    }

    static deactivateGameFilters() {
        document.getElementById('gameFiltersContainer').style.maxHeight = '0px'
        GridOrchestrator.performOrientationChangeActions()
    }
}

GridOrchestrator.template = `
<scrolltotop-component></scrolltotop-component>
<livestreamer-component></livestreamer-component>
    <div id="menusWrapper" class="row g-0 fixed-top" style="z-index: 1056;">
        <main-navbar class="col-12"></main-navbar>
    </div>
    <div id="outsideMenus" class="row g-0">
        <div data-advert="left" class="col-1 side-advertisement" style="overflow: hidden;border-radius: 10px;z-index: 1055"></div>
        <div id="contentColumn" class="col-10 offset-1">
            <div id="gameFiltersContainer" class="mt-0 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-1 mt-xxl-1">
                <game-navbar></game-navbar>
            </div>
            <main data-barba="container" data-barba-namespace="splash-page" class="row g-0 g-0"></main>
        </div>
        <div  data-advert="right" class="col-1 side-advertisement" style="right: 0;overflow: hidden;border-radius: 10px;z-index: 1055"></div>
        <div id="footerContainer" class="col-12 text-center" ">
            <footer-container></footer-container>
        </div>
    </div>
`;

GridOrchestrator.reg('grid-orchestrator');
