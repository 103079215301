export const greekTranslation =
{
    "metaTags": {
        "description": "Ανακάλυψε τoν απόλυτο ελληνικό eSports προορισμό! Livestreams, Άρθρα, Προβλέψεις και Περισσότερα! Γίνε μέλος της οικογένειας μας στο Discord τώρα!"
    },
    "homePage": {
        "latestAnalyses": 'ΤΕΛΕΥΤΑΙΕΣ ΑΙ ΑΝΑΛΥΣΕΙΣ'
    },
    "gamerShowcase": {
        "showGamer": "Δείξε μου το προφίλ του Gamer",
        "modalHeader": "Το προφίλ του",
    },
    "networkGuard": {
        "title": "Ώπα!",
        "description": "Αργή ή καθόλου σύνδεση στο ίντερνετ.",
    },
    "navMainMenu": {
        "Home": "Αρχική",
        "Tournaments": "Τουρνουά",
        "Matches": "Αγώνες",
        "Giveaways": "Κέρδισε",
        "Casinos": "Στοιχηματικές",
        "AboutUs": "Σχετικά με εμάς",
        "Streamers": "Streamers",
        "News": "Νέα",
        "navDesktopTitle": "MyEsports",
    },
    "hotmatchTranslations": {
        "playerNationalities": "Συμμετέχουν παίχτες από {{countries}}",
        "playerAges": "Ο νεότερος παίχτης είναι {{Age}} χρονών",
        "viewers": "{{maxviewers}} Θεατές",
        "alternativeTimeLineHours": "Βλέπετε το {{RGN}}ο παιχνίδι. {{hourz}} ώρα και {{minutez}} λεπτά που ξεκίνησε η σειρά!",
        "alternativeTimeLineMinutes": "Βλέπετε το {{RGN}}ο παιχνίδι. {{minutez}} λεπτά που ξεκίνησε η σειρά!",
        "badgeHot": `<i class="me-2 bi bi-fire"></i>ΚΟΡΥΦΑΙΟ`,
        "noLiveMsg": `<h2>Μείνετε ψύχραιμοι, οι αγώνες είναι προ των πυλών!</h2><p>Φαίνεται ότι η {{hotGame}} σκηνή κάνει μια παύση. Συνεχίστε να τσεκάρετε!</p>`,
    },
    "matchTranslations": {
        "matchMatchUpcoming": `<span class="my-2 badge bg-primary text-resize-badge countdownBadge">Ξεκινάει σε {{minutes}} λεπτά</span>`,
        "matchMatchLive": `<span class="my-2 badge bg-danger live-animation text-resize-badge countdownBadge"><i class=""></i>ΖΩΝΤΑΝΑ</span>`,
        "matchMatchFinished": `<span class="my-2 badge bg-secondary text-resize-badge countdownBadge">Τελικό</span>`,
        "matchMatchPostponed": `<span class="my-2 badge bg-warning text-resize-badge countdownBadge">Αναβολή</span>`,
        "matchMatchCanceled": `<span class="my-2 badge bg-warning text-resize-badge countdownBadge">Ακυρώθηκε</span>`,
        "matchMatchAboutTo": `<span class="my-2 badge bg-primary text-resize-badge countdownBadge">Ξεκινάει..</span>`,
        "homeMatchDHM": `<span class="m-0 badge bg-secondary text-resize-badge countdownBadge">{{days}}μ {{hours}}ω {{minutes}}λ</span>`,
        "homeMatchHM": `<span class="m-0 badge bg-secondary text-resize-badge countdownBadge">{{hours}}ω {{minutes}}λ</span>`,
        "homeMatchM": `<span class="m-0 badge bg-secondary text-resize-badge countdownBadge">{{minutes}}λ</span>`,
        "homeMatchLive": `<span class="m-0 badge bg-danger text-resize-badge countdownBadge"><i class=""></i>ΖΩΝΤΑΝΑ</span>`,
        "homeMatchAboutTo": `<span class="m-0 badge bg-secondary text-resize-badge countdownBadge">Ξεκινάει</span>`,
        "pillLive": "ΖΩΝΤΑΝΑ",
        "pillUpcoming": "ΠΡΟΣΕΧΩΣ",
        "pillFinished": "ΤΕΛΙΚΑ",
        "pillPredictions": "ΠΡΟΒΛΕΨΕΙΣ",
        "pillAll": "ΟΛΑ",
        "pillFavorites": `<i class="bi bi-star-fill" alt="fav-tab"></i> ΑΓΑΠΗΜΕΝΑ`,
        "bestofX": "Στις {{gamesNumber}} νίκες",
        "bestof1": "Μονός αγώνας",
        "bestof2": "2 παιχνίδια",
        "allGamesPlayed": "{{gamesNumber}} παιχνίδια",
        "firstToX": "Στις {{gamesNumber}} νίκες",
        "generalDrop": "{{matchType}}",
        "showMoreMatches": "Περισσότερα",
        "emptyGameFilterMessage": "Δεν υπάρχουν αγώνες {{game}} προς το παρόν.",
        "emptyStateFilterMessage": "Δεν υπάρχουν αγώνες {{game}} με τα επιλεγμένα φίλτρα.",
        "emptyPredictionFilterMessage": "Δεν υπάρχουν προγνωστικά {{predGame}} για την επιλεγμένη ημερομηνία.",
    },
    "postMatchTranslations": {
        "ageLabel": "Ηλικία: ",
        "positionLabel": "Θέση: ",
        "h2hNoRecentMsg": "Δεν υπάρχουν πρόσφατοι αγώνες μεταξύ των δύο ομάδων",
        "matchHistory": "Ιστορικό Αγώνων",
        "h2hPill": "Μεταξύ τους",
        "badgeWon": "Ν",
        "badgeLost": "Η",
        "badgeDraw": "Ι",
        "noVideoMessage": "Δεν υπάρχει διαθέσιμο βίντεο για αυτό τον αγώνα",
        "analysis": "Το μοντέλο τεχνίτης νοημοσύνης μας 'PlayPulse' αυτήν την στιγμή αναλύει τον αγώνα. Ρίξτε ξανά μια ματιά σε λίγο προκειμένου να διαβάσετε την ανάλυση!",
        "whatDoesAiThink": "Τι σκέφτεται το eSports AI μας \"PlayPulse\", για τον αγώνα;",
        "aiAnalysis": "AI ΑΝΑΛΥΣΗ",
    },
    "newsTranslations": {
        "noRecentNewsMsg": `<h2>Δεν υπάρχουν πρόσφατα άρθρα</h2><p>Δυστυχώς, δεν υπάρχουν πρόσφατα {{newsGame}} άρθρα.</p>`,
        "newsTitle": "ΑΡΘΡΑ",
        "writtenAt": "Γράφτηκε στις",
        "footerArticle": "🔥  Σου αρεσε το αρθρο; 🔥",
        "footerArticleFollow": "Ακολούθησέ μας για περισσότερα συναρπαστικά νέα!",
        "newsSeeAll": "Περισσότερα",
        "seeMore": "Δείτε περισσότερα...",
        "goBack": "Επιστροφή στα Νέα",
        "tournamentNews": "Τουρνουά",
        "rosterNews": "Ομάδες",
        "gameNews": "Γενικά",
    },
    "footer": {
        "about": "Καλώς ήρθατε στο MyEsports, τον ολοκληρωμένο προορισμό για κάθε φίλο των eSports. Με μια βαθιά αγάπη και πάθος για τον κόσμο του gaming, δημιουργήσαμε έναν χώρο όπου η κοινότητα των eSports θα μπορεί να βρει τα πάντα: από ζωντανές μεταδόσεις αγώνων και εκδηλώσεων μέχρι προγνωστικά, αναλύσεις και άρθρα για όλα τα είδη παιχνιδιών.",
        "followUs": "Ακολουθήστε μας",
        "usefulLinks": "Χρήσιμοι σύνδεσμοι",
        "contactUs": "Επικοινωνία",
        "aboutUs": "Σχετικά με εμάς",
        "eula": "Όροι Χρήσης",
        "privacy": "Πολιτική Απορρήτου",
        "faq": "Συχνές Ερωτήσεις",
        "Authors": "Αρθρογράφοι",
    },
    "tournamentsTranslations": {
        "noDateMsg": "Μη καθορισμένη Ημ. Λήξης",
        "tier": "Κατηγορία",
        "emptyTournamentFilterMessage": "Δεν υπάρχουν διαθέσιμα {{tourGame}} τουρνουά με τα επιλεγμένα φίλτρα, προς το παρόν.",
        "pillOngoing": "ΣΕ ΕΞΕΛΙΞΗ",
        "pillUpcoming": "ΠΡΟΣΕΧΩΣ",
        "pillFinished": "ΟΛΟΚΛΗΡΩΘΗΚΑΝ",
        "pillAll": "ΟΛΑ",
        "tournamentLive": `<span class="my-2 badge bg-danger text-resize-badge"><i class="me-1 me-md-2 bi bi-broadcast"></i>ΣΕ ΕΞΕΛΙΞΗ</span>`,
        "tournamentFinished": `<span class="my-2 badge bg-secondary text-resize-badge">ΟΛΟΚΛΗΡΩΘΗΚΕ</span>`,
        "tournamentUpDHM": `<span class="my-2 badge bg-primary text-resize-badge">Ξεκινάει σε {{days}} ημέρες</span>`,
        "tournamentUp1D": `<span class="my-2 badge bg-primary text-resize-badge">Ξεκινάει σε {{days}} ημέρα</span>`,
        "tournamentUpHM": `<span class="my-2 badge bg-primary text-resize-badge">Ξεκινάει σε {{hours}}ω {{minutes}}λ</span>`,
        "tournamentUpM": `<span class="my-2 badge bg-primary text-resize-badge">Ξεκινάει σε {{minutes}}λ</span>`,
        "noPrizePoolAvailable": "N/A",
        "euroPrizePool": "€",
        "usdPrizePool": "$",
        "teamsCompete": "Ομάδες συμμετέχουν",
        "hotTournamentsTitle": "ΔΗΜΟΦΙΛΗ ΤΟΥΡΝΟΥΑ",
        "hotTournamentFixedLabel": `ΣΕ ΕΞΕΛΙΞΗ`,
        "noHotTournamentsMsg": `<h2>Δεν υπάρχουν τουρνουά σε εξέλιξη</h2><p>Δυστυχώς, δεν υπάρχουν {{hTgame}} τουρνουά σε εξέλιξη προς το παρόν</p>`,
        "noHotTournamentsButton": "Δείτε όλα τα τουρνουά",
        "noHotTournamentsButton2": "Περισσότερα",
        "clickHotTournaments": "Κάντε κλικ για περισσότερες πληροφορίες.",
    },
    "postTournamentTranslations": {
        "teamsCompetePost": `Συμμετέχουν: <span class="text-resize-tournament-info-right text-white">{{teams}}</span> ομάδες`,
        "playersCompetePost": `Συμμετέχουν: <span class="text-resize-tournament-info-right text-white">{{teams}}</span> παίχτες`,
        "noPrizePoolAvailablePost": `Χρηματικό έπαθλο: <span class="text-resize-tournament-info-right text-white">-</span>`,
        "euroPrizePoolPost": `Χρηματικό έπαθλο: <span class="text-resize-tournament-info-right text-white">{{money}}€</span>`,
        "usdPrizePoolPost": `Χρηματικό έπαθλο: <span class="text-resize-tournament-info-right text-white">{{money}}$</span>`,
        "tierPost": "Κατηγορία:",
        "winnerPost": "Νικητής Τουρνουά:",
        "startPost": "Έναρξη Τουρνουά:",
        "endPost": "Λήξη Τουρνουά:",
        "noDateMsgPost": "Μη καθορισμένη ημ/νία λήξης",
        "ladderWins": "Νίκες",
        "ladderLosses": "Ήττες",
        "ladderTies": "Ισοπαλίες",
        "ladderGamesWon": "Νικημένα παιχνίδια",
        "ladderGamesLost": "Χαμένα παιχνίδια",
        "ladderTieBreaker": "Μπρέικς Ισοπαλίας",
        "upperBracket": "Ανώ Bracket",
        "lowerBracket": "Κατώ Bracket",
        "grandFinal": "Τελικός",
        "semiFinal": "Ημιτελικός",
        "quarterFinal": "Προημιτελικός",
        "roundBrackets": "Γύρος",
        "matchBrackets": "Αγώνας",
        "laddersMatchesTitle": "Αγώνες",
        "week": "Εβδομάδα",
        "liveWeek": "ΖΩΝΤΑΝΑ",
    },
    "predictionsTranslations": {
        "predTitle": "ΠΡΟΓΝΩΣΤΙΚΑ",
        "comingSoon": "Σύντομα διαθέσιμα",
    },
    "banners": {
        "desktopLeft": 'el_desktop_left',
        "desktopRight": 'el_desktop_right',
    },
    "streamerTranslations": {
        "cardFollowers": "Ακόλουθοι",
        "cardInfo": `Ο χρήστης {{user}} είναι {{status}}  αυτή τη στιμγή.`,
        "cardViewers": "Παρακολουθούν ζωντανά!",
        "detailsInformation": "Πληροφορίες Streamer",
        "detailsName": "Όνοματεπώνυμο",
        "detailsNationality": "Εθνικότητα",
        "detailsAge": "Ηλικία",
        "detailsGame": "Παιχνίδια",
        "detailsRole": "Ρόλος",
        "detailsTeam": "Ομάδα",
        "detailsSignature": "Χαρακτηριστικοί Ήρωες",
        "accordionHowTo": `<strong>-Πως να εγγραφώ στη λίστα των streamers του myEsports;</strong>`,
        "accordionHowToReply": `Μπορείς να συμπληρώσεις την φόρμα <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_Rzx7ZW1S-wdS4WKkp5bk2lNoaR-6WGDHGQeAIL_ZECol6g/viewform?usp=sf_link" alt="google form" target="_blank" rel="noopener noreferrer">εδώ</a> ή να μας βρεις στον <a href="https://discord.gg/myesports" target="_blank" rel="noopener noreferrer">discord server</a> μας και να έρθεις σε επικοινωνία με κάποιον moderator ο οποίος θα σε καθοδηγήσει.`,
        "accordionEarn": `<strong>-Τι θα κερδίσω αν streamάρω με τα banners του myEsports;</strong>`,
        "accordionEarnReply": `Την παρουσία σου στο site μας και διαφήμιση σε ολα μας τα Social Media.`,
        "accordionGraphics": `<strong>-Που θα βρω τα γραφικά για το stream μου;</strong>`,
        "accordionGraphicsReply": `Θα σου τα στείλει η ομάδα μας, μαζί με οδηγίες για το πως θα τα χρησιμοποιήσεις.`,
        "accordionHowToStream": `<strong>-Θέλω να γίνω streamer αλλά δεν έχω ξανά streamαρει, πως ξεκινάω;</strong>`,
        "accordionHowToStreamReply": `Μην ανυσηχείς, θα σε καθοδηγήσει η ομάδα μας για το πως θα ξεκινήσεις, για περισσότερες πληροφορίες μπορείς να μας βρεις στον <a href="https://discord.gg/myesports" target="_blank" rel="noopener noreferrer">discord server</a> μας.`,
        "accordionViewers": `<strong>-Πόσους viewers πρέπει να έχω για να με δεχτείτε;</strong>`,
        "accordionViewersReply": `Δεν υπάρχει κάποιος αριθμός viewers που θα σε κάνει να μην είσαι ευπρόσδεκτος στην ομάδα μας. Το μόνο που χρειάζεται να έχεις είναι όρεξη και καθημερινό πρόγραμμα live streaming.`,
    },
    "authorsTranslations": {
        "title": 'Γνωρίστε τους αρθρογράφους του MyEsports',
    },
}
